<template>
  <!-- <transition :name="isWap ? 'fade' : ''" :duration="isWap ? 300 : 1"> -->
  <div class="detail-order-overlay" @click.self="handleCloseTradeInfo">
    <div class="market-detail modify-trade">
      <img
        class="close-img"
        src="~@/assets/images/common/close-d.png"
        alt=""
        @click="handleCloseTradeInfo"
      />
      <p class="modify-title">设置止盈止损</p>
      <div class="market-title" @click="handleCloseTradeInfo">
        <i></i>
        <span>设置止盈止损</span>
      </div>
      <div class="market-content scrollbar">
        <p class="order-date">
          {{ orderInfo.b ? orderInfo.b.replace("T", " ") : "" }}
        </p>
        <div class="symbol-info">
          <p class="name-info">
            <span class="name-ch">{{ orderInfo.cname }}</span>
            <span class="name-en">{{ orderInfo.s }}</span>
          </p>
          <div class="price-info">
            <p class="rate">
              {{ orderInfo.c === 0 ? "买入" : "卖出" }}
              {{ (orderInfo.v / 100).toFixed(2) }}手
            </p>
            <p class="rate">开仓价：{{ orderInfo.f }}</p>
            <p
              class="rate"
              :class="
                (orderInfo.c === 0 ? orderInfo.Bid : orderInfo.Ask) -
                  orderInfo.f >
                0
                  ? orderInfo.f -
                      (orderInfo.c ? orderInfo.Bid : orderInfo.Ask) ===
                    0
                    ? ''
                    : 'up'
                  : 'down'
              "
            >
              {{
                orderInfo.c
                  ? orderInfo.Bid || orderInfo.bd
                  : orderInfo.Ask || orderInfo.ak
              }}
            </p>
          </div>
        </div>
        <div class="market-order">
          <p class="profit-loss-title">止盈止损</p>
          <div class="profit-loss-div">
            <div class="order-input">
              <p class="order-input-title">
                <span class="span-title" :class="zyClass ? '' : 'error'"
                  >止盈</span
                >
                <span
                  class="span-price"
                  :class="zyClass ? '' : 'error'"
                  v-show="limitProfitPrice"
                  >[{{ zyfuhao === 1 ? "≥" : "≤" }}{{ limitProfitPrice }}]</span
                >
              </p>
              <div class="order-input-div">
                <img
                  src="~@/assets/images/market/decrease.png"
                  alt=""
                  class="order-input-img decrease"
                  @click="handleStopSurplusChange(false)"
                />
                <input
                  type="text"
                  class="order-input-number"
                  placeholder="设置止盈"
                  v-model="profitPrice"
                  @input="handleSurPlusInput"
                  @focus="handleProfitFocus"
                />
                <img
                  src="~@/assets/images/market/add.png"
                  alt=""
                  class="order-input-img add"
                  @click="handleStopSurplusChange(true)"
                />
                <p class="estimated-profit-loss">
                  [ 预计盈亏：{{ zyProfit }} ]
                </p>
              </div>
            </div>
            <div class="order-input">
              <p class="order-input-title">
                <span class="span-title" :class="zsClass ? '' : 'error'"
                  >止损</span
                >
                <span
                  class="span-price"
                  :class="zsClass ? '' : 'error'"
                  v-show="limitLossPrice"
                  >[{{ zsfuhao === 1 ? "≥" : "≤"
                  }}{{ Math.abs(limitLossPrice) }}]</span
                >
              </p>
              <div class="order-input-div">
                <img
                  src="~@/assets/images/market/decrease.png"
                  alt=""
                  class="order-input-img decrease"
                  @click="handleStopLossChange(false)"
                />
                <input
                  type="text"
                  class="order-input-number"
                  placeholder="设置止损"
                  v-model="lossPrice"
                  @input="handleStopLossInput"
                  @focus="handleLossFocus"
                />
                <img
                  src="~@/assets/images/market/add.png"
                  alt=""
                  class="order-input-img add"
                  @click="handleStopLossChange(true)"
                />
                <p class="estimated-profit-loss">
                  [ 预计盈亏：{{ zsProfit }} ]
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="market-btn">
        <button class="button cancel-button" @click="handleCloseTradeInfo">
          取消
        </button>
        <button
          class="button confirm-button"
          @click="handleModifyOrder"
          :disabled="isDisabled"
        >
          确认
        </button>
      </div>
    </div>
  </div>
  <!-- </transition> -->
</template>

<script>
//import Dialog from "@/components/dialog.vue";
export default {
  name: "ModifyOrder",
  // components: {
  //   Dialog,
  // },
  props: {
    isWap: Boolean,
  },
  data() {
    return {
      info: {}, //修改的订单信息
      orderId: undefined,
      lossPrice: "", // 止损价格
      profitPrice: "", // 止盈价格
      zyClass: true, // 判断止盈价格是否输入正确
      zsClass: true, // 判断止损价格是否正确
      ssClass: true, // 判断手数输入是否正确
      zyfuhao: 1, //止盈符号
      zsfuhao: 1, //止损符号
      isDisabled: false,
      //dialogObj: {},
      succInfo: {},
    };
  },
  computed: {
    orderInfo() {
      if (!this.orderId) {
        return {};
      }
      let order = this.$store.state.socket.tradeList.find(
        (item) => item.i === this.orderId
      );
      if (!order.s) {
        console.log(order, this.orderId);
      }
      let item = this.$store.state.socket.symbolList.find(
        (item) => item.name === order.s
      );
      // order.bWave = item.bWave ? item.bWave : "";
      // order.aWave = item.aWave ? item.aWave : "";
      order.cname = item.cname ? item.cname : "";
      order.j = item.j;
      order.z = item.z;
      order.p1 = item.p1;
      order.Bid = parseFloat(item.b).toFixed(item.p);
      order.Ask = parseFloat(item.a).toFixed(item.p);
      return order;
    },
    // 止损价格限制(最大最小)
    limitLossPrice() {
      let price = this.orderInfo.g || 0;
      // 价格
      if (this.orderInfo.c === 0) {
        // 市价买入
        price = (
          (this.orderInfo.Bid || 0) * 1 -
          (this.orderInfo["j"] * this.orderInfo["p1"] || 0) * 1
        ).toFixed(this.orderInfo.d);
      } else if (this.orderInfo.c === 1) {
        // 市价卖出
        price = (
          (this.orderInfo.Ask || 0) * 1 +
          (this.orderInfo["j"] * this.orderInfo["p1"] || 0) * 1
        ).toFixed(this.orderInfo.d);
      }
      return price;
    },
    // 止盈价格限制(最大最小)
    limitProfitPrice() {
      let price = this.orderInfo.h || 0;
      // 市价 价格
      if (this.orderInfo.c === 1) {
        // 市价卖出
        price = (
          (this.orderInfo.Ask || 0) * 1 -
          (this.orderInfo["j"] * this.orderInfo["p1"] || 0) * 1
        ).toFixed(this.orderInfo.d);
      } else if (this.orderInfo.c === 0) {
        // 市价买入
        price = (
          (this.orderInfo.Bid || 0) * 1 +
          (this.orderInfo["j"] * this.orderInfo["p1"] || 0) * 1
        ).toFixed(this.orderInfo.d);
      }
      return price;
    },
    //止盈  预计盈亏
    zyProfit() {
      if (!this.profitPrice) {
        return "--";
      }
      let openPrice = this.orderInfo.f;
      let profitPrice = this.profitPrice;
      let zyProfit = 0;
      let handLot = (this.orderInfo.v / 100).toFixed(2);
      if (this.orderInfo.c === 1) {
        //卖出   profit = (开仓价-当前买价)*手数*合约单位
        zyProfit =
          (openPrice * 1 - profitPrice * 1) * handLot * this.orderInfo.z;
      } else {
        zyProfit =
          (profitPrice * 1 - openPrice * 1) * handLot * this.orderInfo.z;
      }
      return zyProfit.toFixed(this.orderInfo.d);
    },
    //止损  预计盈亏
    zsProfit() {
      if (!this.lossPrice) {
        return "--";
      }
      let openPrice = this.orderInfo.f;
      let lossPrice = this.lossPrice;
      let zsProfit = 0;
      let handLot = (this.orderInfo.v / 100).toFixed(2);
      if (this.orderInfo.c === 1) {
        //卖出   profit = (开仓价-当前买价)*手数*合约单位
        zsProfit = (openPrice * 1 - lossPrice * 1) * handLot * this.orderInfo.z;
      } else {
        //买入   profit = (当前卖价-开仓价)*手数*合约单位
        zsProfit = (lossPrice * 1 - openPrice * 1) * handLot * this.orderInfo.z;
      }
      return zsProfit.toFixed(this.orderInfo.d);
    },
  },
  methods: {
    //是否显示订单操作
    handleCloseTradeInfo() {
      this.$emit("closeTradeInfo");
    },
    handleOrderInfo(item) {
      this.info = item;
      this.orderId = item.i;
      //this.handlot = (item.v / 100).toFixed(2);
      this.lossPrice = Object.assign({}, this.orderInfo).g || "";
      this.profitPrice = Object.assign({}, this.orderInfo).h || "";

      this.handleZsfuhao();
      this.handleZyfuhao();
    },
    //  止损符号
    handleZsfuhao() {
      // 1 大于等于 2 小于等于
      // 市价 价格
      if (this.orderInfo.c === 0) {
        // 买入
        this.zsfuhao = 2;
      } else {
        // 卖出
        this.zsfuhao = 1;
      }
    },
    // 止盈符号
    handleZyfuhao() {
      // 1 大于等于 2 小于等于
      // 市价 价格
      if (this.orderInfo.c === 0) {
        // 买入
        this.zyfuhao = 1;
      } else {
        // 卖出
        this.zyfuhao = 2;
      }
    },
    //是否超过止损限制值
    handleZsClass(value) {
      if (this.zsfuhao === 1) {
        // 大于等于
        this.zsClass = value - this.limitLossPrice >= 0 ? true : false;
      } else {
        // 小于等于
        this.zsClass = value - this.limitLossPrice <= 0 ? true : false;
      }
    },
    //是否超过止盈限制值
    handleZyClass(value) {
      if (this.zyfuhao === 1) {
        // 大于等于
        this.zyClass = value - this.limitProfitPrice >= 0 ? true : false;
      } else {
        // 小于等于
        this.zyClass = value - this.limitProfitPrice <= 0 ? true : false;
      }
    },
    // 止损输入中
    handleStopLossInput() {
      this.lossPrice = this.handleNumFloat(this.lossPrice, this.orderInfo.d);
      this.handleZsClass(this.lossPrice);
    },
    handleLossFocus() {
      if (!this.lossPrice) {
        this.handleDefaultStopLoss();
      }
    },
    // 止损默认价格计算
    handleDefaultStopLoss() {
      let pointPrice = (this.orderInfo["j"] * this.orderInfo["p1"] || 0) * 1; // 默认的挂单价格浮动差值
      // 市价
      if (this.orderInfo.c === 0) {
        // 买入
        this.lossPrice = (this.orderInfo.Bid * 1 - pointPrice * 1).toFixed(
          this.orderInfo.d
        );
      } else {
        // 卖出
        this.lossPrice = (this.orderInfo.Ask * 1 + pointPrice * 1).toFixed(
          this.orderInfo.d
        );
      }
    },
    // 止损价格加减
    handleStopLossChange(bool) {
      if (this.lossPrice === "" || this.lossPrice === 0) {
        this.handleDefaultStopLoss();
      } else {
        if (bool) {
          this.lossPrice = (this.lossPrice * 1 + 0.01).toFixed(
            this.orderInfo.d
          );
        } else {
          this.lossPrice = (this.lossPrice * 1 - 0.01).toFixed(
            this.orderInfo.d
          );
        }
        this.handleZsClass(this.lossPrice);
      }
    },
    handleProfitFocus() {
      if (!this.profitPrice) {
        this.handleDefaultStopSurplus();
      }
    },
    // 止盈输入中
    handleSurPlusInput() {
      this.profitPrice = this.handleNumFloat(
        this.profitPrice,
        this.orderInfo.d
      );
      this.handleZyClass(this.profitPrice);
    },
    // 止盈默认价格计算
    handleDefaultStopSurplus() {
      let pointPrice = (this.orderInfo["j"] * this.orderInfo["p1"] || 0) * 1; // 默认的挂单价格浮动差值
      // 市价
      if (this.orderInfo.c === 0) {
        // 买入
        this.profitPrice = (this.orderInfo.Bid * 1 + pointPrice * 1).toFixed(
          this.orderInfo.d
        );
      } else {
        // 卖出
        this.profitPrice = (this.orderInfo.Ask * 1 - pointPrice * 1).toFixed(
          this.orderInfo.d
        );
      }
    },
    // 止盈价格加减
    handleStopSurplusChange(bool) {
      if (this.profitPrice === "" || this.profitPrice === 0) {
        this.handleDefaultStopSurplus();
      } else {
        if (bool) {
          this.profitPrice = (this.profitPrice * 1 + 0.01).toFixed(
            this.orderInfo.d
          );
        } else {
          this.profitPrice = (this.profitPrice * 1 - 0.01).toFixed(
            this.orderInfo.d
          );
        }
        this.handleZyClass(this.profitPrice);
      }
    },
    //控制输入的小数点位数
    handleNumFloat(value, digits) {
      if (value.toString().indexOf(".") === -1) {
        return value;
      }

      let valueArr = value.toString().split(".");
      digits = digits || this.orderInfo.d;
      if (valueArr[1].length < digits) {
        return value;
      }
      valueArr[1] = valueArr[1].substring(0, digits);
      return (valueArr[0] + "." + valueArr[1]) * 1;
    },
    // 修改
    handleModifyOrder() {
      if (!this.zyClass) {
        this.$toast("止盈止损价格设置异常");
        return false;
      } else if (!this.zsClass) {
        this.$toast("止盈止损价格设置异常");
        return false;
      }
      this.isDisabled = true;
      this.$toast.loading({
        duration: 0,
        overlay: true,
        message: "修改中...",
      });
      let valid = this.zyClass && this.zsClass;
      if (valid) {
        let params = {
          id: this.orderId, // 订单ID
          lossPrice: this.lossPrice, // 止损价
          profitPrice: this.profitPrice, // 止盈价
          //openPrice: this.orderInfo.f,   // 挂单需要这个字段
        };
        this.$store.state.socket.trader.setOrderPrice(
          params,
          this.handleModifyOrderCb
        );
        //15秒没反应提示超时
        setTimeout(() => {
          if (this.isDisabled) {
            this.isDisabled = false;
            this.$toast.clear();
            this.$toast("提交超时，请重新操作");
          }
        }, 15000);
      }
    },
    handleModifyOrderCb(data) {
      //console.log(data);
      this.isDisabled = false;
      this.$toast.clear();
      if (data.status == 1) {
        this.$emit("closeTradeInfo");
        this.$emit("showDialog", data.data);
      } else {
        this.$toast.fail(data.message);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/market/detail.scss";
@import "@/assets/scss/market/order.scss";
</style>
