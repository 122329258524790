<template>
  <!-- <transition :name="isWap ? 'fade' : ''" :duration="isWap ? 300 : 1"> -->
  <div class="detail-order-overlay" @click.self="handleCloseTradeInfo">
    <div class="market-detail modify-trade">
      <img
        class="close-img"
        src="~@/assets/images/common/close-d.png"
        alt=""
        @click="handleCloseTradeInfo"
      />
      <p class="modify-title">设置平仓</p>
      <div class="market-title" @click="handleCloseTradeInfo">
        <i></i>
        <span>设置平仓</span>
      </div>
      <div class="market-content scrollbar">
        <div class="order-info-item">
          <p>
            <span>订单编号</span
            ><span>{{
              orderInfo && orderInfo.i ? "#" + orderInfo.i : "--"
            }}</span>
          </p>
          <p>
            <span>品种</span
            ><span>{{
              orderInfo && orderInfo.cname ? orderInfo.cname : orderInfo.s
            }}</span>
          </p>
          <p>
            <span>持仓手数</span
            ><span
              >{{ orderInfo.c === 0 ? "买入" : "卖出"
              }}{{ (orderInfo.v / 100).toFixed(2) }}</span
            >
          </p>
        </div>
        <div class="market-order">
          <div class="order-lots order-input">
            <p class="order-input-title">
              <span class="span-title" :class="ssClass ? '' : 'error'"
                >平仓手数</span
              >
              <span class="span-price" :class="ssClass ? '' : 'error'"
                >[0.01-{{ (orderInfo.v / 100).toFixed(2) }}]</span
              >
            </p>
            <div class="order-input-div">
              <img
                src="~@/assets/images/market/decrease.png"
                alt=""
                class="order-input-img decrease"
                @click="handleHandLotChange(false)"
              />
              <input
                type="text"
                class="order-input-number"
                v-model="handLot"
                @input="handleHandLotInput"
                @blur="handleHandLotBlur"
              />
              <img
                src="~@/assets/images/market/add.png"
                alt=""
                class="order-input-img add"
                @click="handleHandLotChange(true)"
              />
            </div>
          </div>
          <p class="profit-loss-p">
            <span class="profit-loss-name">预估浮动盈亏</span>
            <span class="profit-loss-val" :class="profit > 0 ? 'up' : 'down'">{{
              profit
            }}</span>
          </p>
          <div class="tips">
            <p class="tips-title">温馨提示</p>
            <p class="tips-p">
              1、市场交易模式下，实际成交价格可能和请求价格存在一定差异，预估浮
              动盈亏仅供参考。
            </p>
            <p class="tips-p">
              2、平仓过程中如果遇到问题，请及时联系在线客服。
            </p>
          </div>
        </div>
      </div>

      <div class="market-btn">
        <button class="button cancel-button" @click="handleCloseTradeInfo">
          取消
        </button>
        <button
          class="button confirm-button"
          @click="handleCloseOrder"
          :disabled="isDisabled"
        >
          确认
        </button>
      </div>
    </div>
  </div>
  <!-- </transition> -->
</template>

<script>
//import Dialog from "@/components/dialog.vue";
export default {
  name: "closeOrder",
  // components: {
  //   Dialog,
  // },
  props: {
    isWap: Boolean,
  },
  data() {
    return {
      info: {}, //修改的订单信息
      orderId: undefined,
      handLot: "",
      succInfo: {},
      //dialogObj: {},
      isDisabled: false,
      ssClass: true,
    };
  },
  computed: {
    orderInfo() {
      if (!this.orderId) {
        return {};
      }
      let order = this.$store.state.socket.tradeList.find(
        (item) => item.i === this.orderId
      );
      let item = this.$store.state.socket.symbolList.find(
        (item) => item.name === order.s
      );
      // order.bWave = item.bWave ? item.bWave : "";
      // order.aWave = item.aWave ? item.aWave : "";
      order.cname = item.cname ? item.cname : "";
      order.j = item.j;
      order.Bid = parseFloat(item.b).toFixed(item.p);
      order.Ask = parseFloat(item.a).toFixed(item.p);
      return order;
    },
    profit() {
      if (
        !this.handLot ||
        this.handLot == (this.orderInfo.v / 100).toFixed(2)
      ) {
        return this.orderInfo.p;
      }
      let profit =
        (this.orderInfo.p / (this.orderInfo.v / 100).toFixed(2)) * this.handLot;
      return profit.toFixed(this.orderInfo.d);
    },
  },
  methods: {
    //是否显示订单操作
    handleCloseTradeInfo() {
      this.$emit("closeTradeInfo");
    },
    handleOrderInfo(item) {
      console.log(item);
      this.info = item;
      this.orderId = item.i;
      this.handLot = (item.v / 100).toFixed(2);
    },
    // 手数加减
    handleHandLotChange(bool) {
      if (bool) {
        if (this.handLot < this.orderInfo.v / 100) {
          this.handLot = (this.handLot * 1 + 0.01).toFixed(2);
        }
      } else {
        if (this.handLot > 0.01) {
          this.handLot = (this.handLot * 1 - 0.01).toFixed(2);
        }
      }
      if (this.handLot < 0.01) {
        this.handLot = 0.01;
      } else if (this.handLot > this.orderInfo.v / 100) {
        this.handLot = (this.orderInfo.v / 100).toFixed(2);
      }
    },
    // 手数输入完成
    handleHandLotInput() {
      if (this.handLot < 0.01 || this.handLot > this.orderInfo.v / 100) {
        this.ssClass = false;
      } else {
        this.ssClass = true;
      }
      //控制小数点位数
      this.handLot = this.handleNumFloat(this.handLot, 2);
    },
    handleHandLotBlur() {
      let reg = /^\d+(\.\d{0,2})?$/;
      if (reg.test(this.handLot)) {
        if (this.handLot < 0.01 || this.handLot === "") {
          this.handLot = 0.01;
        } else if (this.handLot > this.orderInfo.v / 100) {
          this.handLot = this.orderInfo.v / 100;
        }
      } else {
        this.handLot = 0.01;
      }
    },
    //控制输入的小数点位数
    handleNumFloat(val, digits) {
      if (val.indexOf(".") === -1) {
        return val;
      }

      let valueArr = val.split(".");
      digits = digits || this.orderInfo.d;
      if (valueArr[1].length < digits) {
        return val;
      }
      valueArr[1] = valueArr[1].substring(0, digits);
      return (valueArr[0] + "." + valueArr[1]) * 1;
    },
    // 平仓
    handleCloseOrder() {
      this.isDisabled = true;
      //平仓
      let params = {
        id: this.orderInfo.i, //订单ID
        volum: this.handLot, //平仓手数
      };
      
      this.$toast.loading({
        duration: 0,
        overlay: true,
        message: "平仓中...",
      });
      console.log(this.$store.state.socket.trader)
      this.$store.state.socket.trader.closeOrder(params, this.closeOrderCb);
      //15秒没反应提示超时
      setTimeout(() => {
        if (this.isDisabled) {
          this.isDisabled = false;
          this.$toast.clear();
          this.$toast("提交超时，请重新操作");
        }
      }, 5000);
    },
    closeOrderCb(data) {
      this.isDisabled = false;
      this.$toast.clear();
      console.log("closeOrderCb");
      if (data.status == 1) {
        //关闭修改订单
        this.handleCloseTradeInfo();
        this.$emit("showDialog", data.data);
      } else {
        this.$toast.fail(data.message);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/market/detail.scss";
@import "@/assets/scss/market/order.scss";
</style>
