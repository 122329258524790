<template>
  <div class="trade-page main-div" :class="isShowRight ? 'open-right' : ''">
    <PageBack />
    <div class="left-content">
      <Tab
        ref="tradeTab"
        :tabList="tabList"
        @changeTab="handleChangeTab"
        :tabIndex="tabIndex"
      />
      <div class="trade-list hold-list" v-if="tabIndex == 1">
        <template v-if="holdList.length">
          <div class="trade-item" v-for="item in holdList" :key="item.i">
            <div
              class="item-info collapse"
              @click="handleShowOrderInfo(item)"
              :class="item.i == currOrderId ? 'act' : ''"
            >
              <div class="item-name-price">
                <p class="item-name">
                  {{ item.cname ? item.cname : item.s }}
                  <span :class="item.c === 0 ? 'buy' : 'sale'"
                    >{{ item.c === 0 ? "买入" : "卖出"
                    }}{{ (item.v / 100).toFixed(2) }}</span
                  >
                </p>
                <div class="item-price">
                  <p class="open-price">开仓价：{{ item.f.toFixed(item.d) }}</p>
                  <p
                    class="curr-price"
                    :class="
                      (item.c === 0 ? item.Bid : item.Ask) - item.f > 0
                        ? item.f - (item.c === 0 ? item.Bid : item.Ask) === 0
                          ? ''
                          : 'up'
                        : 'down'
                    "
                  >
                    现价：{{
                      item.c === 0 ? item.Bid || item.bd : item.Ask || item.ak
                    }}
                  </p>
                </div>
              </div>
              <p
                class="profit"
                v-if="item.yk"
                :class="item.yk > 0 ? 'up' : 'down'"
              >
                {{ item.yk }}
              </p>
              <p class="profit" v-else :class="item.p > 0 ? 'up' : 'down'">
                {{ item.p }}
              </p>
            </div>
            <div
              class="item-detail"
              :class="item.i == currOrderId ? 'act' : ''"
            >
              <div class="detail-info hold">
                <p class="detail-date">{{ item.b.replace("T", " ") }}</p>
                <p class="detail-item">
                  <span
                    >止损：{{ item.g ? item.g.toFixed(item.d) : "0.00" }}</span
                  ><span>订单号：{{ item.i }}</span>
                </p>
                <p class="detail-item">
                  <span
                    >止盈：{{ item.h ? item.h.toFixed(item.d) : "0.00" }}</span
                  ><span
                    >库存费：{{ item.q ? item.q.toFixed(2) : "0.00" }}
                  </span>
                </p>
                <p class="detail-item">
                  <span>税费：{{ item.t ? item.t.toFixed(2) : "0.00" }}</span
                  ><span
                    >手续费：{{ item.k ? item.k.toFixed(2) : "0.00" }}</span
                  >
                </p>
              </div>
              <div class="item-btn" :class="item.isChart ? '' : 'item-btn-min'">
                <button
                  class="button trade-btn"
                  @click="handleGoCharts(item)"
                  v-if="item.isChart"
                >
                  图表
                </button>
                <button
                  class="button trade-btn"
                  @click="handleModifyOrder(item)"
                >
                  止盈止损
                </button>
                <button
                  class="button trade-btn"
                  @click="handleCloseOrder(item)"
                >
                  平仓
                </button>
              </div>
            </div>
            <p
              class="arrow"
              :class="item.i == currOrderId ? 'act' : ''"
              @click="handleShowOrderInfo(item)"
            >
              <img src="~@/assets/images/funds/arrow-down.png" />
            </p>
          </div>
        </template>
        <div class="no-list" v-else>暂无数据</div>
      </div>
      <div class="trade-list hung-list" v-if="tabIndex == 2">
        <template v-if="hungList.length">
          <div class="trade-item" v-for="item in hungList" :key="item.i">
            <div
              class="item-info collapse"
              @click="handleShowOrderInfo(item)"
              :class="item.i == currOrderId ? 'act' : ''"
            >
              <div class="item-name-price">
                <p class="item-name">
                  {{ item.cname ? item.cname : item.s }}
                  <span :class="item.c === 2 || item.c === 4 ? 'buy' : 'sale'"
                    >{{ item.c === 2 || item.c === 4 ? "买入" : "卖出"
                    }}{{ (item.v / 100).toFixed(2) }}</span
                  >
                </p>
                <div class="item-price">
                  <p class="open-price">开仓价：{{ item.f.toFixed(item.d) }}</p>
                  <p
                    class="curr-price"
                    :class="
                      (item.c === 2 || item.c === 4 ? item.Bid : item.Ask) -
                        item.f >
                      0
                        ? item.f -
                            (item.c === 2 || item.c === 4
                              ? item.Bid
                              : item.Ask) ===
                          0
                          ? ''
                          : 'up'
                        : 'down'
                    "
                  >
                    现价：{{
                      item.c === 2 || item.c === 4
                        ? item.Bid || item.bd
                        : item.Ask || item.ak
                    }}
                  </p>
                </div>
              </div>

              <p class="profit"></p>
            </div>
            <div
              class="item-detail"
              :class="item.i == currOrderId ? 'act' : ''"
            >
              <div class="detail-info">
                <p class="detail-date">
                  {{ item.b ? item.b.replace("T", " ") : "" }}
                </p>
                <p class="detail-item">
                  <span
                    >止损：{{ item.g ? item.g.toFixed(item.d) : "0.00" }}</span
                  ><span>订单号：{{ item.i }}</span>
                </p>
                <p class="detail-item">
                  <span
                    >止盈：{{ item.h ? item.h.toFixed(item.d) : "0.00" }}</span
                  ><span>有效期：{{ item.e.replace("T", " ") }} </span>
                </p>
              </div>
              <div class="item-btn" :class="item.isChart ? '' : 'item-btn-min'">
                <button
                  class="button trade-btn"
                  @click="handleGoCharts(item)"
                  v-if="item.isChart"
                >
                  图表
                </button>
                <button
                  class="button trade-btn"
                  @click="handleModifyHungOrder(item)"
                >
                  修改挂单
                </button>
                <button
                  class="button trade-btn"
                  @click="handleCloseHungOrder(item)"
                >
                  撤单
                </button>
              </div>
            </div>
            <p
              class="arrow"
              :class="item.i == currOrderId ? 'act' : ''"
              @click="handleShowOrderInfo(item)"
            >
              <img src="~@/assets/images/funds/arrow-down.png" />
            </p>
          </div>
        </template>
        <div class="no-list" v-else>暂无数据</div>
      </div>
      <div class="trade-list search-list" v-if="tabIndex == 3">
        <p class="search-item" @click="handleHistoryTrade">
          <span>查询历史盈亏</span>
          <img src="~@/assets/images/common/arrow.png" alt="" />
        </p>
        <p class="search-item" @click="handleHistoryFunds">
          <span>查询历史资金流水</span>
          <img src="~@/assets/images/common/arrow.png" alt="" />
        </p>
      </div>
    </div>
    <div class="right-content">
      <CloseOrder
        ref="CloseOrder"
        :isWap="isWap"
        v-show="isShowCloseOrder"
        @closeTradeInfo="handleCloseTradeInfo"
        @showDialog="handleCloseOrderDialog"
      />
      <ModifyOrder
        ref="ModifyOrder"
        :isWap="isWap"
        v-show="isShowModifyOrder"
        @closeTradeInfo="handleCloseTradeInfo"
        @showDialog="handleModifyOrderDialog"
      />
      <ModifyHungOrder
        ref="ModifyHungOrder"
        :isWap="isWap"
        v-show="isShowModifyHungOrder"
        @closeTradeInfo="handleCloseTradeInfo"
        @showDialog="handleModifyHungOrderDialog"
      />
    </div>
    <Dialog
      :dialogObj="dialogObj"
      @confirm="handleCloseSuccConfirm"
      @cancel="handleCloseSuccCancel"
    />
    <!-- 修改盈亏、挂单成功提示 -->
    <Dialog
      ref="orderSucc"
      :dialogObj="dialogModifyObj"
      @confirm="handleOrderSuccConfirm"
      @cancel="handleOrderSuccCancel"
    >
      <div class="dialog-detail">
        <p class="dialog-p">
          <span class="p-title">订单编号</span>
          <span class="p-val">{{ succInfo.i }}</span>
        </p>
        <p class="dialog-p">
          <span class="p-title">交易品种</span>
          <span class="p-val">{{
            succInfo.cname ? succInfo.cname : succInfo.s
          }}</span>
        </p>
        <p class="dialog-p">
          <span class="p-title">交易类型</span>
          <span class="p-val">{{ formatOrderType(succInfo.c) }}</span>
        </p>
        <p class="dialog-p">
          <span class="p-title">开仓价格</span>
          <span class="p-val">{{ succInfo.f }}</span>
        </p>
        <p class="dialog-p">
          <span class="p-title">止损价格</span>
          <span class="p-val">{{ succInfo.h }}</span>
        </p>
        <p class="dialog-p">
          <span class="p-title">止盈价格</span>
          <span class="p-val">{{ succInfo.g }}</span>
        </p>
      </div>
    </Dialog>
    <!-- 平仓成功提示 -->
    <Dialog
      ref="orderSucc"
      :dialogObj="dialogCloseObj"
      @confirm="handleOrderSuccConfirm"
      @cancel="handleOrderSuccCancel"
    >
      <div class="dialog-detail">
        <p class="dialog-p">
          <span class="p-title">盈亏</span>
          <span
            class="p-val"
            :class="succInfo.p > 0 ? 'up' : succInfo.p == 0 ? '' : 'down'"
            >{{ succInfo.p }}</span
          >
        </p>
        <p class="dialog-p">
          <span class="p-title">开仓价格</span>
          <span class="p-val">{{ succInfo.f }}</span>
        </p>
        <p class="dialog-p">
          <span class="p-title">平仓价格</span>
          <span class="p-val">{{ succInfo.u }}</span>
        </p>
        <p class="dialog-p">
          <span class="p-title">止损价格</span>
          <span class="p-val">{{ succInfo.h }}</span>
        </p>
        <p class="dialog-p">
          <span class="p-title">止盈价格</span>
          <span class="p-val">{{ succInfo.g }}</span>
        </p>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Tab from "@/components/tab.vue";
import CloseOrder from "@/components/trade/closeOrder.vue";
import ModifyOrder from "@/components/trade/modifyOrder.vue";
import ModifyHungOrder from "@/components/trade/modifyHungOrder.vue";
import PageBack from "@/components/pageBack.vue";
//import { collapse } from "@/mixins/collapse.js";
import { mapState } from "vuex";
import Dialog from "@/components/dialog.vue";
export default {
  name: "trade",
  components: {
    Tab,
    CloseOrder,
    ModifyOrder,
    ModifyHungOrder,
    PageBack,
    Dialog,
  },
  //mixins: [collapse],
  data() {
    return {
      tabList: [
        {
          label: "持仓",
          value: "1",
        },
        {
          label: "挂单",
          value: "2",
        },
        {
          label: "查询",
          value: "3",
        },
      ],
      tabIndex: 1, //选项卡默认值
      currOrderId: "", // 当前选择订单的ID
      currOrderInfo: {}, // 当前选择订单详情
      hungOrderId: "", // 挂单展示操作按钮ID
      isShowCloseOrder: false, //是否显示订单操作
      isShowModifyHungOrder: false,
      isShowModifyOrder: false,
      dialogObj: {},
      dialogModifyObj: {},
      dialogCloseObj: {},
      succInfo: {},
      isShowRight: false,
    };
  },
  computed: {
    isWap() {
      return this.$store.state.common.isWap;
    },
    ...mapState("socket", ["trader"]),
    holdList() {
      let list = this.$store.state.socket.tradeList
        .filter((item) => item.c === 1 || item.c === 0)
        .map((item) => {
          if (item.s == "GOLD" || item.s == "SILVER") {
            item.isChart = true;
          }
          return item;
        });
      //console.log(list);
      //console.log(list.sort(this.handleCompare("b")));
      return list.sort(this.handleCompare("b"));
    },
    hungList() {
      let list = this.$store.state.socket.tradeList
        .filter((item) => item.c !== 1 && item.c !== 0)
        .map((item) => {
          if (item.s == "GOLD" || item.s == "SILVER") {
            item.isChart = true;
          }
          return item;
        });
      return list.sort(this.handleCompare("b"));
    },
    historyTradeList() {
      return this.$store.state.socket.historyTradeList;
    },
  },
  methods: {
    // 订单类型切换
    handleChangeTab(index) {
      this.tabIndex = index;
      this.holdOrderId = "";
      this.holdOrderVolum = "";
      this.hungOrderId = "";
      this.isShowRight = false;
    },
    // 持仓单点击事件
    handleShowOrderInfo(item) {
      //console.log(item);
      this.currOrderId = this.currOrderId === item.i ? "" : item.i;
      if (this.holdOrderId) {
        this.currOrderInfo = item;
      }
    },
    // 跳转图表
    handleGoCharts(item) {
      this.$router.push({
        name: "index",
        query: { s: item.s },
      });
    },
    // 平仓（持仓单）
    handleCloseOrder(item) {
      this.isShowRight = true;
      this.isShowCloseOrder = true;
      this.isShowModifyOrder = false;
      this.$refs["CloseOrder"].handleOrderInfo(item);
    },
    // 修改订单（持仓单）
    handleModifyOrder(item) {
      this.isShowRight = true;
      this.isShowModifyOrder = true;
      this.isShowCloseOrder = false;
      this.$refs["ModifyOrder"].handleOrderInfo(item);
    },
    // 历史盈亏
    handleHistoryTrade() {
      this.$router.push({
        name: "historyTrade",
      });
    },
    // 资金流水
    handleHistoryFunds() {
      this.$router.push({
        name: "historyFunds",
      });
    },
    // 撤单（挂单）
    handleCloseHungOrder(item) {
      let type = this.formatOrderType(item.c);
      this.dialogObj = {
        isShow: true,
        title: "温馨提示",
        content: `您确定要撤销订单：#${item.i}${
          item.cname ? item.cname : item.s
        }，${type}${item.f}吗？`,
        confirmText: "确定",
        cancelText: "取消",
      };
    },
    handleCloseSuccConfirm() {
      this.trader.removePendOrder(this.currOrderId, this.handleHungOrderCb);
    },
    handleCloseSuccCancel() {
      this.dialogObj = {
        isShow: false,
      };
    },
    //挂单 撤单成功的回调函数
    handleHungOrderCb(data) {
      if (data.status == 1) {
        this.$toast.success(data.message);
      } else {
        this.$toast.fail(data.message);
      }
    },
    formatOrderType(type) {
      let t = "";
      switch (type) {
        case 0:
          t = "市价买入";
          break;
        case 1:
          t = "市价卖出";
          break;
        case 2:
          t = "限价买入";
          break;
        case 3:
          t = "限价卖出";
          break;
        case 4:
          t = "止损买入";
          break;
        case 5:
          t = "止损卖出";
          break;
      }
      return t;
    },
    // 改单（挂单）
    handleModifyHungOrder(item) {
      this.isShowRight = true;
      this.isShowModifyHungOrder = true;
      this.$refs["ModifyHungOrder"].handleOrderInfo(item);
    },
    // 快速平仓（持仓单）
    handleFastCloseOrder() {
      this.maskObj = {
        show: true,
        text: "您是否确认平仓？",
        confirmText: "确定",
        cancelText: "取消",
      };
    },
    handleFastClose() {
      //console.log(this.holdOrderId, this.holdOrderVolum / 100);
      if (!this.holdOrderId) {
        this.$toast("请选择平仓订单");
        return;
      }
      if (!this.holdOrderVolum) {
        this.$toast("请选择平仓订单的手数");
        return;
      }
      //平仓
      let params = {
        id: this.holdOrderId, //订单ID
        volum: (this.holdOrderVolum / 100).toFixed(2), //平仓手数
      };
      this.$toast.loading({
        message: "平仓中...",
        forbidClick: true,
      });
      this.$store.state.socket.trader.closeOrder(
        params,
        this.handleFastCloseCb
      );
    },
    //挂单 撤单成功的回调函数
    handleFastCloseCb(data) {
      if (data.status == 1) {
        this.$toast.clear();
        this.$toast.success(data.message);
      } else {
        this.$toast.clear();
        this.$toast.fail(data.message);
      }
    },
    //是否显示订单操作
    handleCloseTradeInfo() {
      this.isShowRight = false;
      //防止订单信息已隐藏，右侧动画还未完成，暂停200ms执行
      setTimeout(() => {
        this.isShowCloseOrder = false; //是否显示订单操作
        this.isShowModifyHungOrder = false;
        this.isShowModifyOrder = false;
        this.currOrderInfo = {};
      }, 200);
    },
    handleCloseOrderDialog(info) {
      this.succInfo = info;
      this.dialogCloseObj = {
        isShow: true,
        showSuccImg: true,
        content: "平仓成功",
        confirmText: "继续交易",
        cancelText: "返回首页",
      };
    },
    handleModifyOrderDialog(info) {
      this.succInfo = info;
      this.dialogModifyObj = {
        isShow: true,
        showSuccImg: true,
        content: "设置止盈止损成功",
        confirmText: "继续交易",
        cancelText: "返回首页",
      };
    },
    handleModifyHungOrderDialog(info) {
      this.succInfo = info;
      this.dialogModifyObj = {
        isShow: true,
        showSuccImg: true,
        content: "修改挂单成功",
        confirmText: "继续交易",
        cancelText: "返回首页",
      };
    },
    handleOrderSuccConfirm() {
      //取消弹框
      this.dialogCloseObj = {
        isShow: false,
      };
      this.dialogModifyObj = {
        isShow: false,
      };
      //this.$emit("closeTradeInfo");
    },
    handleOrderSuccCancel() {
      this.$router.push({
        name: "index",
      });
    },
    handleCompare(property) {
      return function (a, b) {
        try {
          let value1 = new Date(a[property].replace("T", " ")).getTime();
          let value2 = new Date(b[property].replace("T", " ")).getTime();
          return value2 - value1;
        } catch (error) {
          return true;
        }
      };
    },
  },
  created() {
    if (this.$route.query.source == 2) {
      this.tabIndex = 2;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/trade.scss";
</style>
