<template>
  <transition :name="isWap ? 'fade' : 'slide-right'">
    <div class="detail-order-overlay" @click.self="handleCloseTradeInfo">
      <div class="market-detail modify-trade">
        <img
          class="close-img"
          src="~@/assets/images/common/close-d.png"
          alt=""
          @click="handleCloseTradeInfo"
        />
        <p class="modify-title">设置止盈止损</p>
        <div class="market-title">
          <i @click="handleCloseTradeInfo"></i>
          <span>修改挂单</span>
        </div>
        <div class="market-content scrollbar">
          <p class="order-date">订单号：{{ orderInfo.i }}</p>
          <div class="symbol-info">
            <p class="name-info">
              <span class="name-ch">{{ orderInfo.cname }}</span>
              <span class="name-en">{{ orderInfo.s }}</span>
            </p>
            <div class="price-info">
              <p class="rate">
                {{ orderInfo.c | formatOrderType }}
                {{ (orderInfo.v / 100).toFixed(2) }}手
              </p>
            </div>
          </div>
          <div class="market-order">
            <div class="order-input">
              <p class="order-input-title">
                <span class="span-title" :class="gdClass ? '' : 'error'"
                  >价格</span
                >
                <span
                  class="span-price"
                  :class="gdClass ? '' : 'error'"
                  v-if="hungLimitPrice"
                  >[{{ gdfuhao === 1 ? "≥" : "≤" }}{{ hungLimitPrice }}]</span
                >
              </p>
              <div class="order-input-div">
                <img
                  src="~@/assets/images/market/decrease.png"
                  alt=""
                  class="order-input-img decrease"
                  @click="handleHangPriceChange(false)"
                />
                <input
                  type="text"
                  class="order-input-number"
                  placeholder="挂单价格"
                  v-model="hungPrice"
                  @input="handleHungPriceInput"
                />
                <img
                  src="~@/assets/images/market/add.png"
                  alt=""
                  class="order-input-img add"
                  @click="handleHangPriceChange(true)"
                />
              </div>
            </div>
            <p class="profit-loss-title">止盈止损</p>
            <div class="profit-loss-div">
              <div class="order-input">
                <p class="order-input-title">
                  <span class="span-title" :class="zyClass ? '' : 'error'"
                    >止盈</span
                  >
                  <span
                    class="span-price"
                    :class="zyClass ? '' : 'error'"
                    v-show="limitProfitPrice"
                    >[{{ zyfuhao === 1 ? "≥" : "≤"
                    }}{{ limitProfitPrice }}]</span
                  >
                </p>
                <div class="order-input-div">
                  <img
                    src="~@/assets/images/market/decrease.png"
                    alt=""
                    class="order-input-img decrease"
                    @click="handleStopSurplusChange(false)"
                  />
                  <input
                    type="text"
                    class="order-input-number"
                    placeholder="设置止盈"
                    v-model="profitPrice"
                    @input="handleSurPlusInput"
                    @focus="handleProfitFocus"
                  />
                  <img
                    src="~@/assets/images/market/add.png"
                    alt=""
                    class="order-input-img add"
                    @click="handleStopSurplusChange(true)"
                  />
                  <p class="estimated-profit-loss">
                    [ 预计盈亏：{{ zyProfit }} ]
                  </p>
                </div>
              </div>
              <div class="order-input">
                <p class="order-input-title">
                  <span class="span-title" :class="zsClass ? '' : 'error'"
                    >止损</span
                  >
                  <span
                    class="span-price"
                    :class="zsClass ? '' : 'error'"
                    v-show="limitLossPrice"
                    >[{{ zsfuhao === 1 ? "≥" : "≤"
                    }}{{ Math.abs(limitLossPrice) }}]</span
                  >
                </p>
                <div class="order-input-div">
                  <img
                    src="~@/assets/images/market/decrease.png"
                    alt=""
                    class="order-input-img decrease"
                    @click="handleStopLossChange(false)"
                  />
                  <input
                    type="text"
                    class="order-input-number"
                    placeholder="设置止损"
                    v-model="lossPrice"
                    @input="handleStopLossInput"
                    @focus="handleLossFocus"
                  />
                  <img
                    src="~@/assets/images/market/add.png"
                    alt=""
                    class="order-input-img add"
                    @click="handleStopLossChange(true)"
                  />
                  <p class="estimated-profit-loss">
                    [ 预计盈亏：{{ zsProfit }} ]
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="market-btn">
          <button class="button cancel-button" @click="handleCloseTradeInfo">
            取消
          </button>
          <button
            class="button confirm-button"
            @click="handleModifyOrder"
            :disabled="isDisabled"
          >
            确认
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
//import Dialog from "@/components/dialog.vue";
export default {
  name: "ModifyHungOrder",
  // components: {
  //   Dialog,
  // },
  props: {
    isWap: Boolean,
  },
  data() {
    return {
      info: {}, //修改的订单信息
      orderId: undefined,
      hungPrice: "", // 挂单价格
      lossPrice: "", // 止损价格
      profitPrice: "", // 止盈价格
      zyfuhao: 1, //止盈符号
      zsfuhao: 1, //止损符号
      gdfuhao: 1, //挂单符号
      isDisabled: false,
      dialogObj: {},
      succInfo: {},
    };
  },
  filters: {
    formatOrderType(type) {
      let t = "";
      switch (type) {
        case 0:
          t = "市价买入";
          break;
        case 1:
          t = "市价卖出";
          break;
        case 2:
          t = "限价买入";
          break;
        case 3:
          t = "限价卖出";
          break;
        case 4:
          t = "止损买入";
          break;
        case 5:
          t = "止损卖出";
          break;
      }
      return t;
    },
  },
  computed: {
    orderInfo() {
      if (!this.orderId) {
        return {};
      }
      let order = this.$store.state.socket.tradeList.find(
        (item) => item.i === this.orderId
      );
      let item = this.$store.state.socket.symbolList.find(
        (item) => item.name === order.s
      );
      // order.bWave = item.bWave ? item.bWave : "";
      // order.aWave = item.aWave ? item.aWave : "";
      order.cname = item.cname ? item.cname : "";
      order.j = item.j;
      order.z = item.z;
      order.p1 = item.p1;
      order.Bid = parseFloat(item.b).toFixed(item.p);
      order.Ask = parseFloat(item.a).toFixed(item.p);
      return order;
    },
    // 止损价格限制（最大最小）
    limitLossPrice() {
      let price = 0;
      // 挂单
      if (this.orderInfo.c === 2 || this.orderInfo.c === 4) {
        // 挂单买入
        price = (
          (this.hungPrice || 0) * 1 -
          (this.orderInfo["j"] * this.orderInfo["p1"] || 0) * 1
        ).toFixed(this.orderInfo.d);
      } else if (this.orderInfo.c === 3 || this.orderInfo.c === 5) {
        // 挂单卖出
        price = (
          (this.hungPrice || 0) * 1 -
          (this.orderInfo["j"] * this.orderInfo["p1"] || 0) * 1
        ).toFixed(this.orderInfo.d);
      }
      return price;
    },
    zsClass() {
      if (this.lossPrice !== 0 && this.lossPrice !== "") {
        if (this.zsfuhao === 1) {
          return this.lossPrice - this.limitLossPrice >= 0 ? true : false;
        } else {
          // 小于等于
          return this.lossPrice - this.limitLossPrice <= 0 ? true : false;
        }
      } else {
        return true;
      }
    },
    // 止盈价格限制（最大最小）
    limitProfitPrice() {
      let price = 0;
      // 挂单
      if (this.orderInfo.c === 3 || this.orderInfo.c === 5) {
        // 挂单卖出
        price = (
          (this.hungPrice || 0) * 1 -
          (this.orderInfo["j"] * this.orderInfo["p1"] || 0) * 1
        ).toFixed(this.orderInfo.d);
      } else if (this.orderInfo.c === 2 || this.orderInfo.c === 4) {
        // 挂单买入
        price = (
          (this.hungPrice || 0) * 1 +
          (this.orderInfo["j"] * this.orderInfo["p1"] || 0) * 1
        ).toFixed(this.orderInfo.d);
      }
      return price;
    },
    zyClass() {
      if (this.profitPrice !== 0 && this.profitPrice !== "") {
        if (this.zyfuhao === 1) {
          // 大于等于
          return this.profitPrice - this.limitProfitPrice >= 0 ? true : false;
        } else {
          // 小于等于
          return this.profitPrice - this.limitProfitPrice <= 0 ? true : false;
        }
      } else {
        return true;
      }
    },
    //止盈  预计盈亏
    zyProfit() {
      if (!this.profitPrice) {
        return "--";
      }
      let openPrice = this.orderInfo.f;
      let profitPrice = this.profitPrice;
      let zyProfit = 0;
      let handLot = (this.orderInfo.v / 100).toFixed(2);
      if (this.orderInfo.c === 1) {
        //卖出   profit = (开仓价-当前买价)*手数*合约单位
        zyProfit =
          (openPrice * 1 - profitPrice * 1) * handLot * this.orderInfo.z;
      } else {
        zyProfit =
          (profitPrice * 1 - openPrice * 1) * handLot * this.orderInfo.z;
      }
      return zyProfit.toFixed(this.orderInfo.d);
    },
    //止损  预计盈亏
    zsProfit() {
      if (!this.lossPrice) {
        return "--";
      }
      let openPrice = this.orderInfo.f;
      let lossPrice = this.lossPrice;
      let zsProfit = 0;
      let handLot = (this.orderInfo.v / 100).toFixed(2);
      if (this.orderInfo.c === 1) {
        //卖出   profit = (开仓价-当前买价)*手数*合约单位
        zsProfit = (openPrice * 1 - lossPrice * 1) * handLot * this.orderInfo.z;
      } else {
        //买入   profit = (当前卖价-开仓价)*手数*合约单位
        zsProfit = (lossPrice * 1 - openPrice * 1) * handLot * this.orderInfo.z;
      }
      return zsProfit.toFixed(this.orderInfo.d);
    },
    // 挂单限制价格（最大最小）
    hungLimitPrice() {
      let price = 0;
      if (this.orderInfo.c === 3 || this.orderInfo.c === 5) {
        // 卖出限价
        price = (
          (this.orderInfo.Bid || 0) * 1 +
          (this.orderInfo["j"] * this.orderInfo["p1"] || 0) * 1
        ).toFixed(this.orderInfo.d);
      } else if (this.orderInfo.c === 2 || this.orderInfo.c === 4) {
        // 买入限价
        price = (
          (this.orderInfo.Ask || 0) * 1 -
          (this.orderInfo["j"] * this.orderInfo["p1"] || 0) * 1
        ).toFixed(this.orderInfo.d);
      }
      return price;
    },
    gdClass() {
      if (this.gdfuhao === 1) {
        // 大于等于
        return this.hungPrice - this.hungLimitPrice >= 0 ? true : false;
      } else {
        // 小于等于
        return this.hungPrice - this.hungLimitPrice <= 0 ? true : false;
      }
    },
  },
  methods: {
    //是否显示订单操作
    handleCloseTradeInfo() {
      this.$emit("closeTradeInfo");
    },
    handleOrderInfo(item) {
      this.info = item;
      this.orderId = item.i;
      this.lossPrice = Object.assign({}, this.orderInfo).g || "";
      this.profitPrice = Object.assign({}, this.orderInfo).h || "";
      this.hungPrice = Object.assign({}, this.orderInfo).f || "";
      //挂单 止盈 止损符号
      this.handleGdfuhao();
      this.handleZyfuhao();
      this.handleZsfuhao();
    },
    // 挂单符号
    handleGdfuhao() {
      // 1 大于等于 2 小于等于
      if (this.orderInfo.c === 3 || this.orderInfo.c === 4) {
        // 卖出限价 || 买入止损
        this.gdfuhao = 1;
      } else if (this.orderInfo.c === 2 || this.orderInfo.c === 5) {
        // 卖出止损 || 买入限价
        this.gdfuhao = 2;
      }
    },
    //  止损符号
    handleZsfuhao() {
      // 1 大于等于 2 小于等于
      // 挂单 价格
      if (this.orderInfo.c === 2 || this.orderInfo.c === 4) {
        // 买入
        this.zsfuhao = 2;
      } else {
        // 卖出
        this.zsfuhao = 1;
      }
    },
    // 止盈符号
    handleZyfuhao() {
      // 1 大于等于 2 小于等于
      // 价格
      if (this.orderInfo.c === 2 || this.orderInfo.c === 4) {
        // 买入
        this.zyfuhao = 1;
      } else {
        // 卖出
        this.zyfuhao = 2;
      }
    },
    // 止损获取焦点
    handleLossFocus() {
      if (!this.lossPrice) {
        this.handleDefaultStopLoss();
      }
    },
    // 挂单情况下价格默认值的计算
    handleHangPrice() {
      let point = (this.orderInfo["j"] * this.orderInfo["p1"] || 0) * 1; // 默认的挂单价格浮动差值
      if (this.orderInfo.c === 2 || this.orderInfo.c === 4) {
        // 买入
        this.hungPrice = (this.orderInfo.Ask * 1 - point * 1).toFixed(
          this.orderInfo.d
        );
      } else if (this.orderInfo.c === 3 || this.orderInfo.c === 5) {
        // 卖出
        this.hungPrice = (this.orderInfo.Bid * 1 + point * 1).toFixed(
          this.orderInfo.d
        );
      }
    },
    // 挂单价格加减
    handleHangPriceChange(bool) {
      if (this.hungPrice === 0 || this.hungPrice === "") {
        this.handleHangPrice();
      } else {
        if (bool) {
          this.hungPrice = (this.hungPrice * 1 + 0.01).toFixed(
            this.orderInfo.d
          );
        } else {
          this.hungPrice = (this.hungPrice * 1 - 0.01).toFixed(
            this.orderInfo.d
          );
        }
      }
      this.handleStopLossInput(this.lossPrice);
      this.handleSurPlusInput(this.profitPrice);
    },
    // 止损默认价格计算
    handleDefaultStopLoss() {
      let pointPrice = (this.orderInfo["j"] * this.orderInfo["p1"] || 0) * 1; // 默认的挂单价格浮动差值
      // 挂单
      if (this.orderInfo.c === 2 || this.orderInfo.c === 4) {
        // 买入 价格
        this.lossPrice = (this.hungPrice * 1 - pointPrice * 1).toFixed(
          this.orderInfo.d
        );
      } else if (this.orderInfo.c === 3 || this.orderInfo.c === 5) {
        // 卖出 价格
        this.lossPrice = (this.hungPrice * 1 + pointPrice * 1).toFixed(
          this.orderInfo.d
        );
      }
      this.handleStopLossInput(this.lossPrice);
    },
    // 挂单价格输入中
    handleHungPriceInput() {
      this.hungPrice = this.handleNumFloat(this.hungPrice);

      this.handleStopLossInput(this.lossPrice);
      this.handleSurPlusInput(this.profitPrice);
    },
    // 止盈输入中
    handleSurPlusInput() {
      this.profitPrice = this.handleNumFloat(this.profitPrice);
    },
    // 止损输入中
    handleStopLossInput() {
      this.lossPrice = this.handleNumFloat(this.lossPrice);
    },
    //控制输入的小数点位数
    handleNumFloat(value, digits) {
      if (value.toString().indexOf(".") === -1) {
        return value;
      }

      let valueArr = value.toString().split(".");
      digits = digits || this.orderInfo.d;
      if (valueArr[1].length < digits) {
        return value;
      }
      valueArr[1] = valueArr[1].substring(0, digits);
      return (valueArr[0] + "." + valueArr[1]) * 1;
    },
    // 止盈默认价格计算
    handleDefaultStopSurplus() {
      let pointPrice = (this.orderInfo["j"] * this.orderInfo["p1"] || 0) * 1; // 默认的挂单价格浮动差值
      // 挂单
      if (this.orderInfo.c === 2 || this.orderInfo.c === 4) {
        // 买入 价格
        this.profitPrice = (this.hungPrice * 1 + pointPrice * 1).toFixed(
          this.orderInfo.d
        );
      } else if (this.orderInfo.c === 3 || this.orderInfo.c === 5) {
        // 卖出 价格
        this.profitPrice = (this.hungPrice * 1 - pointPrice * 1).toFixed(
          this.orderInfo.d
        );
      }
      this.handleSurPlusInput(this.profitPrice);
    },
    // 止盈获取焦健
    handleProfitFocus() {
      if (!this.profitPrice) {
        this.handleDefaultStopSurplus();
      }
    },
    // 止损价格加减
    handleStopLossChange(bool) {
      if (this.lossPrice === "" || this.lossPrice === 0) {
        this.handleDefaultStopLoss();
      } else {
        if (bool) {
          this.lossPrice = (this.lossPrice * 1 + 0.01).toFixed(
            this.orderInfo.d
          );
        } else {
          this.lossPrice = (this.lossPrice * 1 - 0.01).toFixed(
            this.orderInfo.d
          );
        }
      }
    },
    // 止盈价格加减
    handleStopSurplusChange(bool) {
      if (this.profitPrice === "" || this.profitPrice === 0) {
        this.handleDefaultStopSurplus();
      } else {
        if (bool) {
          this.profitPrice = (this.profitPrice * 1 + 0.01).toFixed(
            this.orderInfo.d
          );
        } else {
          this.profitPrice = (this.profitPrice * 1 - 0.01).toFixed(
            this.orderInfo.d
          );
        }
      }
    },
    // 修改
    handleModifyOrder() {
      if (!this.zyClass) {
        this.$toast("止盈止损价格设置异常");
        return false;
      } else if (!this.zsClass) {
        this.$toast("止盈止损价格设置异常");
        return false;
      } else if (!this.gdClass) {
        this.$toast("挂单价格设置异常");
        return false;
      }
      this.isDisabled = true;
      this.$toast.loading({
        duration: 0,
        overlay: true,
        message: "修改中...",
      });
      let valid = this.zyClass && this.zsClass && this.gdClass;
      if (valid) {
        let params = {
          id: this.orderId, // 订单ID
          lossPrice: this.lossPrice, // 止损价
          profitPrice: this.profitPrice, // 止盈价
          openPrice: this.hungPrice, // 挂单需要这个字段
        };
        this.$store.state.socket.trader.setOrderPrice(
          params,
          this.handleModifyOrderCb
        );
        //15秒没反应提示超时
        setTimeout(() => {
          if (this.isDisabled) {
            this.isDisabled = false;
            this.$toast.clear();
            this.$toast("提交超时，请重新操作");
          }
        }, 15000);
      }
    },
    handleModifyOrderCb(data) {
      this.isDisabled = false;
      this.$toast.clear();
      if (data.status == 1) {
        //关闭修改订单
        this.handleCloseTradeInfo();
        this.$emit("showDialog", data.data);
      } else {
        this.$toast.fail(data.message);
      }
    },
    handleOrderSuccConfirm() {
      //取消弹框
      this.dialogObj = {
        isShow: false,
      };
      this.$emit("closeTradeInfo");
    },
    handleOrderSuccCancel() {
      this.$router.push({
        name: "index",
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/market/detail.scss";
@import "@/assets/scss/market/order.scss";
</style>
